export default () => ({

  init() {
    this.checkSize();
    window.addEventListener('resize', () => this.checkSize());
  },
  checkSize() { 
    document.documentElement.style.setProperty('--banner-offset', `-${this.$refs.body.children[0].clientWidth}px`);
    this.$refs.body.style.animation = 'bannerSlide 10s infinite linear';
  },
})