export default () => ({
    scrolled: 0,
    step: 0,
    scrollWidth: 0,
    clientWidth: 0,
    isEnd: false,
    isStart: true,

    init() {
      this.onResize();
      window.addEventListener('resize', () => this.onResize());
      this.$refs.body.addEventListener('scroll', () => this.onScroll());
    },
    
    onResize() {
      this.step = this.$refs.body.children[0].offsetWidth;
      this.scrollWidth = this.$refs.body.scrollWidth;
      this.clientWidth = this.$refs.body.clientWidth;
    },
    onScroll() {
      this.scrolled = this.$refs.body.scrollLeft;
      this.isEnd = false;
      this.isStart = false;

      if (this.scrolled == 0) {
        this.isStart = true;
      } else if (this.scrollWidth - this.scrolled <= this.clientWidth) {
        this.isEnd = true;
      }
    },
    slidePrev() {
      this.scrolled -= this.step;
      this.$refs.body.scrollTo({left: this.scrolled, top: 0, behavior: 'smooth'});
    },
    slideNext() {
      this.scrolled += this.step;
      this.$refs.body.scrollTo({left: this.scrolled, top: 0, behavior: 'smooth'});
    },
})