export default () => ({
  submitted: false,
  success: false,
  error: false,
  email: '',
  options: {
    method: 'GET',
    headers: {
      Accept: 'application/json', 
      'Content-Type': 'application/json'
    }
  },
  
  onSubmit(e) {
    if (!this.email) {
      alert('Email is required');
    }

    this.subscribe()
      .then(response => {
        this.submitted = true;
        this.success = !response.exists;
        this.error = response.exists;
      })
      .catch(err => {
        this.submitted = true;
        this.error = true;
      });

    setTimeout(() => {
      this.submitted = false;
      this.success = false;
      this.error = false;
      this.email = '';
    }, 3000);
  },

  async subscribe() {
    const response = await fetch(`/api/subscribe/${this.email}`, this.options)

    return await response.json();
  }
})