import Alpine from 'alpinejs';
import header from './components/header.js';
import slider from './components/slider.js';
import more from './components/more.js';
import banner from './components/banner.js';
import subscribe from './components/subscribe.js';
import loader from './components/loader.js';

import Masonry from 'masonry-layout';

Alpine.data('header', header);
Alpine.data('slider', slider);
Alpine.data('more', more);
Alpine.data('banner', banner);
Alpine.data('subscribe', subscribe);
Alpine.data('loader', loader);

Alpine.start();

window.addEventListener('load', () => {
  if (document.querySelectorAll('.msnry').length) {
    new Masonry('.msnry', {
      horizontalOrder: true,
      percentPosition: true,
      columnWidth: '.msnry-item',
      itemSelector: '.msnry-item'
    });
  }
})

// checkFaders();
// window.addEventListener('scroll', checkFaders);

// function checkFaders() {
//   var faders = document.querySelectorAll('.fade:not(.faded)');
  
//   if (!faders.length) {
//     window.removeEventListener('scroll', checkFaders);
//     return;
//   }

//   faders.forEach((el) => {
//     if (inScreen(el, .8)) {
//       el.classList.add('faded');
//     }
//   });
// }

// function inScreen(el, k) {
//   let box = el.getBoundingClientRect();

//   return window.innerHeight * k > box.top;
// }
