export default () => ({
    opened: false,
    cookies: !sessionStorage.getItem('visited'),
    
    init() {
        let links = this.$refs.menu.querySelectorAll('a');
        const topOffset = document.querySelector('.header').clientHeight;

        links.forEach((link, i) => {
            let linkParts = link.getAttribute('href').split('#'),
                anchor = document.querySelector('#' + linkParts[1]);

            if (location.href.includes(linkParts[0]) && anchor) {
                let linkWrap = link.parentNode;

                window.addEventListener('scroll', () => {
                    let blockBCR = anchor.parentNode.getBoundingClientRect();

                    linkWrap.classList.remove('nav-current');
                
                    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                        if (blockBCR.top > topOffset && blockBCR.bottom < window.innerHeight) {
                            linkWrap.classList.add('nav-current');
                        }
                    } else if (blockBCR.top < topOffset && blockBCR.bottom > topOffset) {
                        linkWrap.classList.add('nav-current');
                    }
                });

                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.opened = false;
                    anchor.scrollIntoView({
                        behavior: 'smooth'
                    });
                });
            }
        })
    },
})