export default (hDelay, lastDelay, postDelay) => ({
  index: 0,
  hDelay: hDelay,
  lastDelay: lastDelay,
  postDelay: postDelay,
  timer: null,
  loaded: false,
  
  init() {
    if (sessionStorage.getItem('visited')) {
      this.animationDone();
    } else {
      this.timer = setInterval(() => this.loop(), this.hDelay);
      sessionStorage.setItem('visited', true);
    }
  },
  loop() {
    if (this.index == 6) {
      this.animationEnd();
    } else {
      if (this.index != 0) {
        this.$el.querySelector(`#h${this.index}`).classList.add('hHide');
      }
      this.$el.querySelector(`#h${++this.index}`).classList.remove('hHide');
    }
  },
  animationEnd() {
    clearInterval(this.timer);
    this.$el.classList.add('shifted');

    setTimeout(() => this.$el.classList.add('pinned'), this.lastDelay);

    setTimeout(() => this.animationDone(), this.postDelay);
  },
  animationDone() {
    this.loaded = true;
  }
})